let siteUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://app.milkjug.io"
let backendUrl = process.env.NODE_ENV === "development" ? "http://localhost:4040" : "https://backend.milkjug.io"
let isDevelopment = process.env.NODE_ENV === "development"
let isProduction = process.env.NODE_ENV === "production"

module.exports = {
    siteUrl,
    backendUrl,
    isDevelopment,
    isProduction,
}