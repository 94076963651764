// import FallingDots from "../FallingDots"

export default function FullPage404(props){

    let styleObject = {
        width: "calc(100vw - 48px)",
        height: "calc(100vh - 64px)",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "32px",
      }

    return (
        <div {...props} style={styleObject}>
          <h1 style={{fontSize: "20vw", maxWidth: "600px", color: "#2955fe"}}>404</h1>
          <p style={{
              maxWidth: "600px",
              fontSize: "18px"
          }}>Unfortunately this page doesn't exist yet. If you know this is a working code, it may be possible the server is down, please try back later. 
            <br></br>
            <br></br>
          Note: QR codes can only be accessed directly by their link.</p>
        </div>
    )
}