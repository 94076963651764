import { SET_THEME_TO_LIGHT_MODE, SET_THEME_TO_DARK_MODE } from "../actions/types";
import { theme as initialState } from "../initialState";
import constants from "../../constants";

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME_TO_LIGHT_MODE:
      return {
        ...state,
        mode: constants.themeModes.light
      };
    case SET_THEME_TO_DARK_MODE:
      return {
        ...state,
        mode: constants.themeModes.dark
      };
    default:
      return state;
  }
}