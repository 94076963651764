import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from '../../redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import FullPageLoader from '../FullPageLoader';

export default function WrapWithProvider({children}){
  return (
    <Provider store={store}>
      <PersistGate loading={<FullPageLoader/>} persistor={persistor}>
          {children}
      </PersistGate>
    </Provider>
  );
};