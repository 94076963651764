import React from "react"
import { messageQrCode } from "../../../../backend"
import FullPageLoader from "../../../FullPageLoader"
import styles from "./index.module.scss"
import _ from "lodash"

let defaultState = {
    firstName: "",
    email: "",
    message: "",
    isLoading: false,
    errorMessage: null,
    successMessage: null,
}

export default class MessageCode extends React.Component {
    
    constructor(props){
        super(props)

        this.state = _.cloneDeep(defaultState)

        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleSubmit(e){
        e.preventDefault()
        this.setState({
            isLoading: true
        })

        let submitData = {
            firstName: this.state.firstName,
            email: this.state.email,
            message: this.state.message,
            uuid: this.props.qr_code.uuid
        }

        console.log({submitData})

        messageQrCode(submitData).then((data)=>{
            this.setState({
                ..._.cloneDeep(defaultState),
                successMessage: "Your message was sent to the owner!"
            })
            this.props.updateQrCode(data.data.qr_code)
        }).catch((error)=>{
            let errorMessage = "Something happened while sending your message to the backend."
            if (error.isServerResponse){
                errorMessage = error.data.message
            } 
            this.setState({
                isLoading: false,
                errorMessage: errorMessage
            })
        })

    }

    render(){
        
        return (
            <>
                <FullPageLoader positionOverTop={true} loading={this.state.isLoading} />
                <div className={styles.container}>

                    <div className={styles.wrapper}>

                        <section className={styles.description}>
                            <h1>Milkjug</h1>
                            <section style={{fontSize: "42px", marginBottom: "24px"}}>🎉</section>
                            <section className={styles.codeDetailsContainer}>
                                <h2 className={styles.itemName}>{this.props.qr_code.name}</h2>
                                <p>{this.props.qr_code.description.split("\n").map((line, index)=>{
                                    return (
                                        <>
                                            {index !== 0 ? <br /> : null}
                                            <span>{line}</span>
                                        </>
                                    )
                                })}</p>
                            </section>
                        </section>

                        {this.state.successMessage && (
                            <section className={styles.successMessage}>
                                <p>{this.state.successMessage}</p>
                            </section>
                        )}

                        {this.state.errorMessage && (
                            <section className={styles.errorMessage}>
                                <p>{this.state.errorMessage}</p>
                            </section>
                        )}

                        <form className={styles.form} onSubmit={this.handleSubmit}>
                            <fieldset disabled={this.state.isLoading}>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>First Name</span>
                                    <input
                                        className={styles.inputField}
                                        type="text"
                                        placeholder="James"
                                        required={true}
                                        value={this.state.firstName}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                firstName: value
                                            })
                                        }}
                                    />
                                </label>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>Email</span>
                                    <input
                                        className={styles.inputField}
                                        type="email"
                                        placeholder="james@example.com"
                                        required={true}
                                        value={this.state.email}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                email: value
                                            })
                                        }}

                                    />
                                </label>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>Private Message</span>
                                    <textarea
                                        className={styles.textField}
                                        type="text"
                                        placeholder="Hey I found your item..."
                                        required={true}
                                        value={this.state.message}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                message: value
                                            })
                                        }}

                                    />
                                </label>
                                <button type="submit" className={styles.submitButton}>Message</button>
                            </fieldset>
                        </form>

                    </div>

                </div>
            </>
        )
    }
}