import FallingDots from "../FallingDots"

export default function FullPageLoader(props){
    let loading = props.loading === undefined ? true : props.loading

    if (!loading) return null

    let dotProps = props.dotProps || {}
    let positionOverTop = props.positionOverTop || false
    let backgroundOpacity = props.backgroundOpacity || .8
    let containerProps = {...dotProps}
    delete containerProps.dotProps

    let styleObject = {
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `rgba(255,255,255,${backgroundOpacity})`
      }

    if (positionOverTop){
        styleObject = {
            ...styleObject,
            zIndex: 100000,
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backdropFilter: "blur(2px)"
        }
    }

    return (
        <div {...containerProps} style={styleObject}>
          <FallingDots {...dotProps} />
        </div>
    )
}