import config from "../config"
import constants from "../constants";
import _ from "lodash"
import { isInstanceOfSome } from "../helpers";
const errors = require("../errors")

const apiVersion = "v1"
const host = config.backendUrl
const options = {};

options.headers = {
  Authorization: "Bearer ",
  Accept: "application/json",
  "Content-Type": "application/json"
};
options.credentials = "include";
options.redirect = "follow";

const getOptions = (accessToken)=>{
  let options_cloned = _.cloneDeep(options)
  let accessToken_cloned = _.cloneDeep(accessToken)
  
  options_cloned.headers.Authorization = `Bearer ${accessToken_cloned}`

  if (!accessToken_cloned){
    delete options_cloned.headers.Authorization
  }

  return options_cloned

}

const catchHandler = error => {
  return new Promise((resolve, reject)=>{
    let r = ()=>{
      return reject(error)
    }

    if (!error){
      return r()
    }
    
    if (!error.message){
      return r()
    }
  
    if (error.message === "" || error.message === " "){
      // console.log("Empty error message")
      return r()
    }

    if (error.message === "Failed to fetch"){
      // console.log("Failed to fetch")
      return r()
    }



    return r()

  })
}

const responseHandler = res => {
    const contentType = res.headers.get("content-type");
    let isJson = false
    if (contentType && contentType.indexOf("application/json") !== -1) {
      isJson = true
    }

    if (!res.ok || res.status !== 200) {
      if (!isJson){
        return res.text().then(data=>{
          let rejectData = {
            isServerResponse: false,
            errorIsServerErrorInstance: false,
            data
          }
  
          return Promise.reject(rejectData);
        })
      }

      return res.json().then(data => {

        let rejectData = {
          isServerResponse: false,
          errorIsServerErrorInstance: false,
          data
        }
        if (data[constants.serverResponseIdentifier[0]] === constants.serverResponseIdentifier[1]){
          rejectData.isServerResponse = true
        }
        
        if (rejectData.isServerResponse && _.isObject(rejectData.data.error) && rejectData.data.error.name){
          let errorInstance = new errors[rejectData.data.error.name](rejectData.data.error.description)
          if (errorInstance && isInstanceOfSome(errorInstance, Object.values(errors))){
            rejectData.errorIsServerErrorInstance = true
            rejectData.data.error = errorInstance

            if (rejectData.data.error instanceof errors.InvalidUserRefreshToken){
            }
          }
        }

        return Promise.reject(rejectData);
      })
    } else {
      return res.json().then(data => {
        return Promise.resolve(data);
      });
    }
};



export async function getQrCode({ uuid }) {
  let opts = getOptions();
  let combinedOptions = _.merge(opts, {
    method: "post",
    body: JSON.stringify({
      uuid
    })
  })

  return fetch(`${host}/api/${apiVersion}/qr`, combinedOptions)
  .then(responseHandler)
  .then((data) => {
    return Promise.resolve(data);
  })
  .catch(catchHandler)
}

export async function claimQrCode({ firstName, email, itemName, description, uuid }) {
  let opts = getOptions();
  let combinedOptions = _.merge(opts, {
    method: "post",
    body: JSON.stringify({
      firstName,
      email,
      itemName,
      description,
      uuid,
    })
  })

  return fetch(`${host}/api/${apiVersion}/qr/claim`, combinedOptions)
  .then(responseHandler)
  .then((data) => {
    return Promise.resolve(data);
  })
  .catch(catchHandler)
}

export async function messageQrCode({ firstName, email, message, uuid }) {
  let opts = getOptions();
  let combinedOptions = _.merge(opts, {
    method: "post",
    body: JSON.stringify({
      firstName,
      email,
      message,
      uuid,
    })
  })

  return fetch(`${host}/api/${apiVersion}/qr/message`, combinedOptions)
  .then(responseHandler)
  .then((data) => {
    return Promise.resolve(data);
  })
  .catch(catchHandler)
}
