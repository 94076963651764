import styles from "./index.module.scss"

export default function FallingDots(props){

    let dynamicStyles = {
        "--dot-color": props.dotColor || "#2955fe",
        "--dot-fade-color": props.dotFadeColor || "rgba(90, 112, 255, 0)",
        "--dot-size": props.dotSize || "15px",
        "--dot-border-radius": props.dotBorderRadius || "100%",
        "--dot-fall-distance": props.dotFallDistance || "15px",
        "--dot-margin-ratio": props.dotMarginRatio || 1.5,
    }

    return (
        <div className={styles.container} style={dynamicStyles}>
            <div className={styles["dot-falling"]} />
        </div>
    )
}