import React from "react"
import { claimQrCode } from "../../../../backend"
import FullPageLoader from "../../../FullPageLoader"
import styles from "./index.module.scss"

export default class ClaimCode extends React.Component {
    
    constructor(props){
        super(props)

        this.state = {
            firstName: "",
            email: "",
            itemName: "",
            description: "",
            isLoading: false,
            errorMessage: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleSubmit(e){
        e.preventDefault()
        this.setState({
            isLoading: true
        })

        let submitData = {
            firstName: this.state.firstName,
            email: this.state.email,
            itemName: this.state.itemName,
            description: this.state.description,
            uuid: this.props.qr_code.uuid
        }

        console.log({submitData})

        claimQrCode(submitData).then((data)=>{
            this.props.updateQrCode(data.data.qr_code)
        }).catch((error)=>{
            let errorMessage = "Something happened while fetching the qr code from the backend."
            if (error.isServerResponse){
                errorMessage = error.data.message
            } 
            this.setState({
                isLoading: false,
                errorMessage: errorMessage
            })
        })

    }

    render(){

        return (
            <>
                <FullPageLoader positionOverTop={true} loading={this.state.isLoading} />
                <div className={styles.container}>

                    <div className={styles.wrapper}>

                        <section className={styles.description}>
                            <h1>Milkjug</h1>
                            <span style={{fontSize: "42px"}}>🖋</span>
                        </section>


                        {this.state.errorMessage && (
                            <section className={styles.errorMessage}>
                                <p>{this.state.errorMessage}</p>
                            </section>
                        )}

                        <form className={styles.form} onSubmit={this.handleSubmit}>
                            <fieldset disabled={this.state.isLoading}>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>First Name</span>
                                    <input
                                        className={styles.inputField}
                                        type="text"
                                        placeholder="James"
                                        required={true}
                                        value={this.state.firstName}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                firstName: value
                                            })
                                        }}
                                    />
                                </label>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>Email</span>
                                    <input
                                        className={styles.inputField}
                                        type="email"
                                        placeholder="james@example.com"
                                        required={true}
                                        value={this.state.email}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                email: value
                                            })
                                        }}

                                    />
                                </label>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>Item Name</span>
                                    <input
                                        className={styles.inputField}
                                        type="text"
                                        placeholder="Water Bottle"
                                        required={true}
                                        value={this.state.itemName}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                itemName: value
                                            })
                                        }}

                                    />
                                </label>
                                <label className={styles.label}>
                                    <span className={styles.labelTitle}>Public Description</span>
                                    <textarea
                                        className={styles.textField}
                                        type="text"
                                        placeholder="My favorite water bottle... Please return if lost!"
                                        required={true}
                                        value={this.state.description}
                                        onChange={(e)=>{
                                            let value = e.target.value
                                            this.setState({
                                                description: value
                                            })
                                        }}

                                    />
                                </label>
                                <button type="submit" className={styles.submitButton}>Claim</button>
                            </fieldset>
                        </form>

                    </div>

                </div>
            </>
        )
    }
}