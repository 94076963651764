// import { nanoid } from 'nanoid';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch} from 'react-router-dom';
// import FallingDots from './components/FallingDots';
import FullPage404 from './components/FullPage404';
import FullPageLoader from './components/FullPageLoader';

// import Loading from './components/loading';
import QrPage from './components/pages/qr';

class App extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: false,
    }
  }

  render(){
    
    if (this.state.loading){
      return (
        <FullPageLoader />
      )
    }

    return (
      <>
            <Switch>

              <Route path="/qr"> 
                <QrPage />
              </Route>
              <Route>
                <FullPage404 />
              </Route>

            </Switch>
      </>
    );

  }

}

const mapStateToProps = (state, ownProps) =>{
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(App);