import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WrapWithParams from '../../wrapWithParams';
// import { nanoid } from "nanoid"
import { getQrCode } from '../../../backend';
import ClaimCode from './ClaimCode';
import MessageCode from './MessageCode';
import FullPageLoader from '../../FullPageLoader';
import FullPage404 from '../../FullPage404';

class ViewQr extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isLoading: true,
            errorMessage: null,
            qr_code: null,
        }

        this.updateQrCode = this.updateQrCode.bind(this)
    }

    updateQrCode(qr){
        this.setState({
            qr_code: qr
        })
    }

    componentDidMount(){
        getQrCode({
            uuid: this.props.uuid
        }).then((data)=>{
            this.setState({qr_code: data.data.qr_code, isLoading: false})
        }).catch(error=>{
            if (error.isServerResponse){
                this.setState({
                    isLoading: false,
                    errorMessage: error.data.message
                })
            } else {
                this.setState({
                    isLoading: false,
                    errorMessage: "Something happened while fetching the qr code from the backend."
                })
            }
        })
    }

    render(){

        // let uuid = this.props.uuid

        if (this.state.isLoading){
            return (
                <>
                    <FullPageLoader />
                </>
            )
        }

        if (this.state.errorMessage){
            return (
                <>
                    <FullPage404 />
                </>
            )
        }

        console.log(this.state)

        if (!this.state.qr_code.user_id){
            // render claim form
            return <ClaimCode qr_code={this.state.qr_code} updateQrCode={this.updateQrCode}/>
        }

        // render message/find form
        return <MessageCode qr_code={this.state.qr_code} updateQrCode={this.updateQrCode}/>
    }

}

const QrPage = (props)=>{
    let { url } = useRouteMatch()
    return (
      <Switch>
        <Route exact path={`${url}/:uuid`}>
            <WrapWithParams component={ViewQr} />
        </Route>
        <Route>
            <FullPage404 />
        </Route>
      </Switch>
    )
}

export default QrPage