import React from "react"
import { useParams } from "react-router"

export default function WrapWithParams(props){
    const params = useParams()
    const Component = props.component
    
    if (Component){
        return <Component {...params} />
    } else {
        return null
    }
}