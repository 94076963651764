import {createStore, applyMiddleware, compose} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers';
import { initialState } from './initialState';
import logger from 'redux-logger';

const middleware = [
  // logger
];

const persistConfig = {
    key: 'root',
    storage,
}

if (process.env.NODE_ENV === "development") middleware.push(logger)

let devtools;
let comp = [applyMiddleware(...middleware)]

if (typeof window !== 'undefined') {
  devtools =
  process.env.NODE_ENV === 'development' && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;
    
  comp.push(devtools)
}



export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  initialState,
  compose(...comp)

);

export const persistor = persistStore(store);