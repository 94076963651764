import _ from "lodash"

export const isInstanceOfAll = (input, arr) =>{
    return arr.every((value)=>{
        return (input instanceof value)
    })
}

export const isInstanceOfSome = (input, arr) =>{
    return arr.some((value)=>{
        return (input instanceof value)
    })
}

export const addMinutesToDate = (date, minutes) => {
    return new Date(date.getTime() + (minutes*60000))
}

export const getDelimiterRegex = ({startDelimiter, endDelimiter}) =>{
    let r = `\\${startDelimiter}(.*?)\\${endDelimiter}`
    let regex = new RegExp(r, "gm")
    return regex
}

export const getVariablesFromDelimitedText = ({text, startDelimiter, endDelimiter}) =>{
    if (!startDelimiter) startDelimiter = "${"
    if (!endDelimiter) endDelimiter = "}"

    let regex = getDelimiterRegex({startDelimiter, endDelimiter})
    let m = Array.from(text.matchAll(regex))
            .map(match => match[1])
            // .map(match=>_.trimStart(match, start))
            // .map(match=>_.trimEnd(match, end))
            .map(_.deburr)
            .map(match=>_.trim(match, " "))
            .filter(m=>m)
    m = _.uniq(m)

    return (m ? (m.length > 0) ? m : [] : [])
}

export const parseKeyValuePair = (text)=>{
    return text
    .split(",")
    .map(p=>{
      
        let [key, value] = p.split(":")
        
        key = _.trim(key)
        key = _.trim(key, "\"")
        value = _.trim(value)
        value = _.trim(value, "\"")
        if (key && value){
            return [key, value]
        } else {
            return null
        }
    
    })
    .filter(kv=>kv)

}